import React from 'react'

const Analytics = () => {
 return (
  <div>
   <h1>Analytics</h1>
  </div>
 )
}

export default Analytics
